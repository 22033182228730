export class GenericHandlerError extends Error {
    public status = 0;

    public statusText = '';

    constructor(message: string, status: number, statusText: string) {
        super(message);
        this.name = 'HandlerError';
        this.status = status;
        this.statusText = statusText;
    }
}

/**
 * GetResponse parse Response object and return parsed json data if response is
 * successful. If response status is 401 (unauthorized), it refers to
 * unauthenticated user (mostly due to server restart). So we should reload
 * page at first.
 *
 * @param response Response object
 * @returns Parsed json object
 */
export const GetResponse = (response: Response) => {
    if (response.status === 401) {
        window.location.href = '/';
    }

    if (!response.ok) {
        throw new GenericHandlerError('cannot get response', response.status, response.statusText);
    }

    return response.json();
};
