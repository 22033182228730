import { IApplication } from '@app/Handlers/API/V1/Applications/Applications';
import { Card, CardTitle, CardBody, Gallery, CardFooter } from '@patternfly/react-core';
import i18next from 'i18next';
import React from 'react';

export interface FCApplicationsProps {
    /** Accessible applications */
    applications: Array<IApplication>;
}

export const Applications: React.FunctionComponent<FCApplicationsProps> = ({ applications }: FCApplicationsProps) => (
    <Card>
        <CardTitle>{i18next.t(`applications.title`)}</CardTitle>
        <CardBody>
            <Gallery hasGutter>
                {applications.map((app) => (
                    <Card
                        component="div"
                        isSelectableRaised
                        onClick={() => window.open(app.url, '_blank', 'noreferrer')}
                    >
                        <CardTitle>{app.name}</CardTitle>
                        <CardBody>
                            <br />
                            {i18next.t(`applications.${app.description}.description`)}
                        </CardBody>
                        <CardFooter>
                            {' '}
                            <a href={app.url} target="_blank" rel="noreferrer">
                                {app.url}
                            </a>
                        </CardFooter>
                    </Card>
                ))}
            </Gallery>
        </CardBody>
    </Card>
);
