/* eslint-disable @typescript-eslint/no-explicit-any */

import { UseQueryResult } from 'react-query';
import { GetResponse } from '@app/Handlers/GenericHandler';
import { PutParams as GetPutParams } from '@app/Handlers/Helpers';

/**
 * API endpoint URL constant.
 */
const URL = '/api/v1/account';

/**
 * React Query storage key.
 */
export const AccountsRQKey = 'accounts';

/**
 * Part of response for user data about an account that describes a user attribute.
 */
export interface Atributes {
    name: string;
    displayName: string;
    required: boolean;
    readOnly: boolean;
    validators: any;
}

/**
 * Part of response for user data about an account that holds description of user attributes.
 */
export interface ProfileMetadata {
    atributes: Array<Atributes>;
}

/**
 * Part of response for user data about an aacount that hold values for defined user attributes.
 */
export interface AttributeValues {
    phoneNumber: string[];
    department: string[];
    locale: string[];
    nickname: string[];
}

/**
 * Structure of data in response for user data about an account.
 */
export interface UserData {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;
    userProfileMetadata: ProfileMetadata;
    attributes: AttributeValues;
}

/**
 * Body of request to update account user data.
 */
export interface IAccountQMRequest {
    csrfToken: string;
    account: UserData;
}

/**
 * GetAccountDataResponse gets user data from endpoint /account.
 * @returns parsed JSON data if the response is successful
 */
export const GetAccountDataResponse = async () => {
    const response = await fetch(`${URL}`);
    return GetResponse(response);
};

/**
 * GetAccount parses response from GetAccountDataResponse and returns them as UserData structure.
 * @param resp the response from GetAccountDataResponse
 * @returns UserData and CSRF token if the response is loaded.
 */
export const GetAccount = (resp: UseQueryResult<any, unknown>): [UserData, string] => [
    (resp.isLoading ? {} : resp.data.user) as UserData,
    resp.isLoading ? '' : resp.data.csrf,
];

/**
 * GetSubmitAccountRequestBody creates request body from UserData structure.
 * @param account request data in structure
 * @returns request data in JSON
 */
const GetSubmitAccountRequestBody = (account: UserData): string => JSON.stringify(account);

/**
 * UpdateAccountRequest performs PUT request to update account used data.
 * @param request contains the body and CSRF token
 * @returns response of the request
 */
export const UpdateAccountRequest = async (request: IAccountQMRequest) => {
    const { csrfToken } = request;
    const { account } = request;
    const requestBody = GetSubmitAccountRequestBody(account);
    const response = await fetch(`${URL}`, GetPutParams(csrfToken, requestBody));
    return GetResponse(response);
};

/**
 * GetEmptyAccount creates an empty account with predefined empty attributes.
 * @returns empty UserData structure.
 */
export const GetEmptyAccount = (): UserData => ({
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    attributes: {
        phoneNumber: [''] as Array<string>,
        department: [''] as Array<string>,
    } as AttributeValues,
    email: '',
    emailVerified: false,
    userProfileMetadata: {} as ProfileMetadata,
});
