import { UseQueryResult } from 'react-query';
import { GetResponse } from '@app/Handlers/GenericHandler';

/**
 * API endpoint URL constant.
 */
const URL = '/auth/user';

/**
 * React Query storage key.
 */
export const AuthUserRQKey = 'auth-user';

/**
 * Response interface for users (or single user)
 * @file auth_user.go
 */
export interface IAuthUser {
    id: string;
    username: string;
    firstname: string;
    lastname: string;
    groups: Array<string>;
    roles: Array<string>;
    email: string;
    locale: string;
    organization_title: string;
    organization_uuid: string;
    contacts: Array<string>;
}

export const GetAuthUserResponse = async () => {
    const response = await fetch(URL);
    return GetResponse(response);
};

export const GetAuthUser = (resp: UseQueryResult<any, unknown>): IAuthUser =>
    (resp.isLoading ? {} : resp.data.user) as IAuthUser;
