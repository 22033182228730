import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import '@app/Translations/i18n';

import '@patternfly/react-core/dist/styles/base.css';
import '@app/app.css';

import AppRoutes from '@app/Router/Router';

const queryClient = new QueryClient();

const App: React.FunctionComponent = () => (
    <QueryClientProvider client={queryClient}>
        <BrowserRouter basename="/-/">
            <AppRoutes />
        </BrowserRouter>
    </QueryClientProvider>
);

export default withTranslation()(App);
