import * as React from 'react';

import { Title, Spinner, EmptyState, EmptyStateBody } from '@patternfly/react-core';
import i18next from '@app/Translations/i18n';

export interface FCStateLoadingProps {
    loading: boolean;
}

export const FCStateLoading: React.FunctionComponent<FCStateLoadingProps> = ({ loading }: FCStateLoadingProps) => {
    if (!loading) {
        return null;
    }

    return (
        <EmptyState>
            <Title headingLevel="h5" size="md">
                {i18next.t('common.Loading')}
            </Title>
            <EmptyStateBody>
                <Spinner size="md" />
            </EmptyStateBody>
        </EmptyState>
    );
};
