import * as React from 'react';
import { FormGroup, TextInput, Checkbox } from '@patternfly/react-core';
import i18next from '@app/Translations/i18n';
import { IUserState, SetValueAtProfile } from '../Helpers/UserState';

export interface FCNicknameProps {
    /** User state connector */
    userState: IUserState;
    /** Form item disabled indicator */
    isDisabled?: boolean;
    /** Form item requirement indicator */
    isRequired?: boolean;
}

export const FCNickname: React.FunctionComponent<FCNicknameProps> = ({
    userState,
    isDisabled = false,
    isRequired = false,
}: FCNicknameProps) => {
    const initial = userState.profile.nickname.length === 0 || userState.profile.nickname.includes('(');
    const [withOrganization, setWithOrganization] = React.useState(initial);

    React.useEffect(() => {
        if (withOrganization) {
            if (userState.profile.email.includes('@')) {
                let domains = userState.profile.email.split('@', 2);
                if (domains.length > 1) {
                    let domain = domains[1];
                    SetValueAtProfile(
                        userState,
                        'nickname',
                        `${userState.profile.firstname} ${userState.profile.lastname} (${domain})`,
                    );
                    return;
                }
            }
        } else {
            SetValueAtProfile(userState, 'nickname', `${userState.profile.firstname} ${userState.profile.lastname}`);
        }
    }, [userState.profile.firstname, userState.profile.lastname, userState.profile.email, withOrganization]);

    return (
        <FormGroup
            label={i18next.t('user.nickname.name')}
            isRequired={isRequired}
            fieldId="form-nickname-title"
            helperText={i18next.t('user.nickname.description')}
            hasNoPaddingTop
        >
            <TextInput
                type="text"
                id="form-nickname"
                name="form-nickname"
                aria-describedby="form-nickname-helper"
                value={userState.profile.nickname}
                isDisabled={true}
            />
            <Checkbox
                id="standalone-nickname-with-organization"
                name="standalone-nickname-with-organization"
                label={i18next.t('user.nickname.checkbox')}
                aria-label=""
                checked={withOrganization}
                isChecked={withOrganization}
                isDisabled={isDisabled}
                onChange={() => {
                    setWithOrganization(!withOrganization);
                }}
            />
        </FormGroup>
    );
};
