import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IOrganization } from '@app/Handlers/API/V1/Organization/Organization';
import { IAccessRequestActivity } from '@app/Handlers/API/V1/Requests/Requests';
import { IWorkingGroup } from '@app/Handlers/API/V1/WorkingGroups/WorkingGroups';

/**
 * Authentication status constants.
 */
export enum AuthStatus {
    UNKNOWN,
    SUCCESS,
    FAILURE,
}

/**
 * ...
 *
 * @param title
 */
export function UseDocumentTitle(title: string) {
    React.useEffect(() => {
        const originalTitle = document.title;
        document.title = title;

        return () => {
            document.title = originalTitle;
        };
    }, [title]);
}

/**
 * Returns date in readable format
 * @param timestamp Current date time value.
 * @returns Formatted date.
 */
export function GetReadableDate(timestamp: Date): string {
    let readable = '';
    readable += `${timestamp.getDate()}. `;
    readable += `${timestamp.getMonth() + 1}. `;
    readable += timestamp.getFullYear();
    return readable;
}

/**
 * Returns time in readable format
 * @param timestamp Current date time value.
 * @returns Formatted time.
 */
export function GetReadableTime(timestamp: Date): string {
    let readable = '';
    readable += `${`0${timestamp.getHours()}`.slice(-2)}:`;
    readable += `${`0${timestamp.getMinutes()}`.slice(-2)}:`;
    readable += `0${timestamp.getSeconds()}`.slice(-2);
    return readable;
}

/**
 * Returns date time in readable format
 * @param timestamp Current date time value.
 * @returns Formatted date time.
 */
export function GetReadableTimestamp(timestamp: Date): string {
    let readable = '';
    readable += `${GetReadableDate(timestamp)} `;
    readable += GetReadableTime(timestamp);
    return readable;
}
