import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
    Nav,
    NavList,
    NavItem,
    Page,
    PageHeader,
    SkipToContent,
    Dropdown,
    DropdownGroup,
    DropdownToggle,
    DropdownItem,
    DropdownSeparator,
    PageHeaderTools,
    PageHeaderToolsGroup,
    PageHeaderToolsGroupProps,
    PageHeaderToolsItem,
    Button,
    Switch,
    PageSidebar,
    NavExpandable,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import { IAppLayout } from '@app/Layouts/Interfaces/IAppLayout';
import { UseDocumentTitle } from '@app/Utils/Helpers';
import { useAuth } from '@app/Router/AuthProvider';
import { routes } from '@app/Router/Routes';
import i18next from '@app/Translations/i18n';

const DefaultAppLayout: React.FunctionComponent<IAppLayout> = ({ children, title }) => {
    UseDocumentTitle(title);
    const { i18n } = useTranslation();

    const auth = useAuth();

    const [languageSet, setLanguageSet] = React.useState(false);

    const currentVersion = auth.ver !== undefined ? auth.ver : '';

    const logoText = 'NÚKIB Account';
    const logoProps = {
        href: '/',
        target: '_blank',
    };

    const defaultVisibility: PageHeaderToolsGroupProps = {
        children: {},
        visibility: {
            default: 'hidden',
            sm: 'hidden',
            md: 'hidden',
            lg: 'visible',
            xl: 'visible',
            '2xl': 'visible',
        },
    };

    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const onDropdownSelect = () => {
        setIsDropdownOpen(false);
    };
    const onDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const [isNavOpen, setIsNavOpen] = React.useState(true);
    const onNavToggle = () => {
        setIsNavOpen(!isNavOpen);
    };

    // TODO: Refactor logout action - not returning back
    const execLogout = () => {
        window.location.href = '/auth/logout';
    };

    // // Re-render page
    React.useEffect(() => {
        if (auth.user !== undefined && !languageSet) {
            if (auth.user.locale === 'en') {
                i18n.changeLanguage('en');
            } else {
                i18n.changeLanguage('cs');
            }
            setLanguageSet(true);
        }
    }, [auth.user !== undefined]);

    const fullname =
        auth.user !== undefined ? (
            <>
                {auth.user.firstname} {auth.user.lastname}
            </>
        ) : (
            <></>
        );

    const userDropdownItems = [
        <DropdownGroup key="user-menu">
            <DropdownItem key="action logout" component="button" onClick={() => execLogout()}>
                {i18next.t('common.Log_out')}
            </DropdownItem>
            <DropdownSeparator key="separator" />
            <DropdownItem key="separated action version" isDisabled component="button">
                {i18next.t('common.Version')}: {currentVersion}
            </DropdownItem>
        </DropdownGroup>,
    ];

    const headerTools = (
        <PageHeaderTools>
            <PageHeaderToolsGroup>
                <PageHeaderToolsItem visibility={defaultVisibility.visibility}>
                    <a href="https://portal.nukib.gov.cz/">
                        <Button style={{ color: '#d2d2d2' }} variant="link" isInline>
                            {i18next.t('common.Back_to_portal')}
                        </Button>
                    </a>
                </PageHeaderToolsItem>
            </PageHeaderToolsGroup>
            <PageHeaderToolsGroup>
                <PageHeaderToolsItem visibility={defaultVisibility.visibility}>
                    <Switch
                        id="reversed-switch"
                        defaultChecked={i18n.language === 'en'}
                        label={<span style={{ color: 'grey' }}>EN</span>}
                        labelOff={<span style={{ color: 'grey' }}>CS</span>}
                        aria-label="EN"
                        isChecked={i18n.language === 'en'}
                        onChange={() => {
                            if (i18n.language === 'en') {
                                i18n.changeLanguage('cs');
                            } else {
                                i18n.changeLanguage('en');
                            }
                        }}
                        isReversed
                    />
                </PageHeaderToolsItem>
            </PageHeaderToolsGroup>
            <PageHeaderToolsGroup>
                <PageHeaderToolsItem visibility={defaultVisibility.visibility}>
                    <Dropdown
                        isPlain
                        position="right"
                        onSelect={onDropdownSelect}
                        isOpen={isDropdownOpen}
                        toggle={<DropdownToggle onToggle={onDropdownToggle}>{fullname}</DropdownToggle>}
                        dropdownItems={userDropdownItems}
                    />
                </PageHeaderToolsItem>
            </PageHeaderToolsGroup>
        </PageHeaderTools>
    );

    const Navigation = (
        <Nav id="nav-primary-simple" theme="dark">
            <NavList id="nav-list-simple">
                {routes().map((route, idx) => (
                    <>
                        {route.displayNav && route.subRoutes.length > 0 && (
                            <NavExpandable title={`${route.label}`} groupId={`${route.label}-${idx}`}>
                                {route.subRoutes.map(
                                    (subroute, subidx) =>
                                        subroute.displayNav && (
                                            <NavItem
                                                key={`${subroute.label}-${subidx}`}
                                                id={`${subroute.label}-${subidx}`}
                                            >
                                                <NavLink to={subroute.path} /*activeClassName="pf-m-current"*/>
                                                    {subroute.label}
                                                </NavLink>
                                            </NavItem>
                                        ),
                                )}
                            </NavExpandable>
                        )}
                        {route.displayNav && route.subRoutes.length === 0 && (
                            <NavItem key={`${route.label}-${idx}`} id={`${route.label}-${idx}`}>
                                <NavLink to={route.path} /*activeClassName="pf-m-current"*/>{route.label}</NavLink>
                            </NavItem>
                        )}
                    </>
                ))}
            </NavList>
        </Nav>
    );

    const Header = (
        <PageHeader
            logo={logoText}
            logoProps={logoProps}
            showNavToggle
            isNavOpen={isNavOpen}
            onNavToggle={onNavToggle}
            headerTools={headerTools}
        />
    );
    const Sidebar = <PageSidebar nav={Navigation} isNavOpen={isNavOpen} />;
    const PageSkipToContent = <SkipToContent href="#primary-app-container">Pokračovat na stránku</SkipToContent>;

    return (
        <Page
            mainContainerId="primary-app-container"
            header={Header}
            sidebar={Sidebar}
            skipToContent={PageSkipToContent}
        >
            {children}
        </Page>
    );
};

export default DefaultAppLayout;
