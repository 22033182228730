import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { EmptyState, EmptyStateBody, EmptyStateIcon, Title, PageSection } from '@patternfly/react-core';
import ExclamationTriangleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-triangle-icon';
import DefaultAppLayout from '@app/Layouts/DefaultAppLayout';

import i18next from '@app/Translations/i18n';

const ErrorForbidden: React.FunctionComponent = () => (
    <DefaultAppLayout user={undefined} version={undefined}>
        <PageSection>
            <EmptyState>
                <EmptyStateIcon icon={ExclamationTriangleIcon} />
                <Title headingLevel="h4" size="lg">
                    {i18next.t('error.access_denied')}
                </Title>
                <EmptyStateBody>{i18next.t('error.access_denied_description')}</EmptyStateBody>
                <br />
                <br />
                <NavLink to="/" className="pf-c-button">
                    {i18next.t('error.return')}
                </NavLink>
            </EmptyState>
        </PageSection>
    </DefaultAppLayout>
);

export default ErrorForbidden;
