import * as React from 'react';

import { IAppLayout } from '@app/Layouts/Interfaces/IAppLayout';
import { UseDocumentTitle } from '@app/Utils/Helpers';

const PublicAppLayout: React.FunctionComponent<IAppLayout> = ({ children, title }) => {
    UseDocumentTitle(title);
    return <div style={{ width: '100%', height: '100%', backgroundColor: '#151515' }}>{children}</div>;
};

export default PublicAppLayout;
