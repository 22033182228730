/* eslint-disable @typescript-eslint/no-explicit-any */

import { UseQueryResult } from 'react-query';
import { GetResponse } from '@app/Handlers/GenericHandler';
import { DeleteParams } from '@app/Handlers/Helpers';

/**
 * API endpoint URL constant.
 */
const URLCredentials = '/api/v1/account/credentials';
export const URLUpdatePassword = '/api/v1/account/update-password';
export const URLConfigureTOTP = '/api/v1/account/configure-totp';
export const URLConfigureSecurityKey = '/api/v1/account/webauthn-register';

/**
 * React Query storage key.
 */
export const CredentialsRQKey = 'credentials';

/**
 * Part of credential response that describes credentials metadata.
 */
export interface CredentialsAtributes {
    id: string;
    type: string;
    createdDate: number;
    userLabel: string;
    credentialData: string;
}

/**
 * Part of credetials response containing user credentails data.
 */
export interface CredentialsMetadata {
    credential: CredentialsAtributes;
}

/**
 * Response interface for credentials request.
 */
export interface CredentialsData {
    isSuccess: any;
    status: unknown;
    type: string;
    category: string;
    displayName: string;
    helptext: string;
    iconCssClass: string;
    updateAction: string;
    removeable: boolean;
    userCredentialMetadatas: CredentialsMetadata[];
}

/**
 * Request interface for credentials device deletion.
 */
export interface IDeleteCredentialQMRequest {
    csrfToken: string;
    uid: string;
}

/**
 * GetAccountCredentialsResponse gets user data from /account/credentials endpoint.
 * @returns parsed JSON data if the response is successful
 */
export const GetAccountCredentialsResponse = async () => {
    const response = await fetch(`${URLCredentials}`);
    return GetResponse(response);
};

/**
 * GetCredentialsData parses response from GetAccountCredentialsResponse and returns them as CredentialsData array.
 * @param resp the response from GetAccountCredentialsResponse
 * @returns CredentialsData array and CSRF token if the response is loaded.
 */
export const GetCredentialsData = (resp: UseQueryResult<any, unknown>): [CredentialsData[], string] => [
    (resp.isLoading ? {} : resp.data.credentials) as CredentialsData[],
    resp.isLoading ? '' : resp.data.csrf,
];

/**
 * DeleteCredentialseviceRequest perform DELETE request to delete a credential, e.g. OTP device
 * @param request reuest data containing CSRF token and id of the redential
 * @returns response of the request
 */
export const DeleteCredentialsRequest = async (request: IDeleteCredentialQMRequest) => {
    const { csrfToken } = request;
    const requestBody = '';
    const response = await fetch(`${URLCredentials}/${request.uid}`, DeleteParams(csrfToken, requestBody));
    return GetResponse(response);
};
