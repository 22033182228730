import { GetReadableTimestamp } from '@app/Utils/Helpers';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    CardHeaderMain,
    DataList,
    DataListItemRow,
    DataListItemCells,
    DataListCell,
    DataListAction,
    DataListItem,
    TextContent,
} from '@patternfly/react-core';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICredentialsRow } from '../Signingin';

export interface FCBasicAuthneticationProps {
    passwords: ICredentialsRow[];
    handleUpdatePassword: () => void;
}

export const FCBasicAuthnetication: React.FunctionComponent<FCBasicAuthneticationProps> = ({
    passwords,
    handleUpdatePassword,
}: FCBasicAuthneticationProps) => {
    let { t } = useTranslation();

    return (
        <Card isPlain>
            <TextContent>
                <CardTitle component="h2">{t('signingin.basic_auth.title')}</CardTitle>
            </TextContent>
            <CardHeader>
                <CardHeaderMain>
                    <b>{t('signingin.basic_auth.password')}</b>
                </CardHeaderMain>
            </CardHeader>
            <CardBody>{t('signingin.basic_auth.info')}</CardBody>
            <CardBody>
                <DataList aria-label="">
                    {passwords.map((row) => (
                        <DataListItem>
                            <DataListItemRow>
                                <DataListItemCells
                                    dataListCells={[
                                        <DataListCell key="user label">{row.userLabel}</DataListCell>,

                                        <DataListCell key="time created">
                                            <b>{i18next.t('signingin.second_factor.created')} </b>
                                            {GetReadableTimestamp(new Date(row.cretedDate))}
                                        </DataListCell>,
                                    ]}
                                />
                                <DataListAction id="" aria-labelledby="" aria-label="">
                                    <Button variant="primary" onClick={handleUpdatePassword}>
                                        {i18next.t('signingin.basic_auth.update')}
                                    </Button>
                                </DataListAction>
                            </DataListItemRow>
                        </DataListItem>
                    ))}
                </DataList>
            </CardBody>
        </Card>
    );
};
