import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { IAuthUser } from '@app/Handlers/Auth/User';

export interface AuthContextType {
    user: IAuthUser | undefined;
    ver: string | undefined;
    login: (loggedUser: IAuthUser, version: string) => void;
    logout: () => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export const useAuth = () => React.useContext(AuthContext);

export const AuthStatusControl = () => {
    const auth = useAuth();

    if (auth.user === undefined) {
        return <Navigate to="/login" />;
    }

    return null;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = React.useState<IAuthUser | undefined>(undefined);
    const [ver, setVer] = React.useState<string | undefined>(undefined);

    const login = (loggedUser: IAuthUser, version: string) => {
        setUser(loggedUser);
        setVer(version);
    };

    const logout = () => {
        setUser(undefined);
    };

    const value = { user, ver, login, logout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const RequireAuth = ({ children }: { children: React.ReactElement }) => {
    const auth = useAuth();
    const location = useLocation();

    if (auth.user === undefined) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};
