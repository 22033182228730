import {
    Form,
    PageSection,
    PageSectionVariants,
    TextContent,
    Text,
    Title,
    TextVariants,
    ActionGroup,
    Button,
    EmptyState,
    EmptyStateIcon,
    Spinner,
} from '@patternfly/react-core';
import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { FCStateLoading } from '@app/Components/States/StateLoading';
import {
    AccountsRQKey,
    GetAccount,
    GetAccountDataResponse,
    IAccountQMRequest,
    UpdateAccountRequest,
} from '@app/Handlers/API/V1/Account/Account';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { FCNotifications, INotificationContent, NotificationVariant } from '@app/Components/Notifications';
import { FCEmail } from './Components/Email';
import { IUserProfile, IUserState } from './Helpers/UserState';
import { FCFirstName, FCFirstNameValidate } from './Components/FirstName';
import { FCLastName, FCLastNameValidate } from './Components/LastName';
import { FCPhoneNumber, FCPhoneNumberValidate } from './Components/PhoneNumber';
import { FCDepartment, FCDepartmentValidate } from './Components/Department';
import { FCLocale } from './Components/Locale';
import { FCNickname } from './Components/Nickname';

export const PageTitle = () => i18next.t('personal_info.title');

enum Status {
    Empty = '',
    Updated = 'updated',
}
enum Operation {
    Empty = '',
    Update = 'update',
}

export const AccountInformation: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const [status, setStatus] = React.useState<Status>(Status.Empty);
    const [operation, setOperation] = React.useState<Operation>(Operation.Empty);

    const userProfileResponse = useQuery(AccountsRQKey, GetAccountDataResponse);
    const [userProfile, accountCSRF] = GetAccount(userProfileResponse);

    const [userStateValue, userStateSetter] = React.useState({} as IUserProfile);
    const userState = { profile: userStateValue, setProfile: userStateSetter } as IUserState;

    const [notifications, setNotifications] = React.useState([] as Array<INotificationContent>);

    const AddNotification = (variant: NotificationVariant, text: string) => {
        const notification: INotificationContent = {
            variant,
            title: text,
        };
        setNotifications([...notifications, notification]);
    };

    const getValueFromAttribute = (attribute: string[]) =>
        attribute === undefined || attribute === null || attribute.length === 0 ? '' : attribute[0];

    const reloadUserState = () => {
        if (userProfileResponse.isSuccess) {
            const changed = {
                id: userProfile.id,
                firstname: userProfile.firstName,
                lastname: userProfile.lastName,
                email: userProfile.email,
                // username: userProfile.username,
                phoneNumber: getValueFromAttribute(userProfile.attributes.phoneNumber),
                locale: getValueFromAttribute(userProfile.attributes.locale),
                department: getValueFromAttribute(userProfile.attributes.department),
                nickname: getValueFromAttribute(userProfile.attributes.nickname),
            } as IUserProfile;
            userStateSetter(changed);
        }
    };

    // Re-render for refetch
    React.useEffect(() => {
        if (userProfileResponse.isSuccess) {
            reloadUserState();
        }
    }, [userProfileResponse.isSuccess]);

    // Re-render for refetch after update
    React.useEffect(() => {
        if (status === Status.Updated) {
            reloadUserState();
            setStatus(Status.Empty);
        }
    }, [status]);

    const accountUpdateMutation = useMutation(UpdateAccountRequest, {
        onSuccess: () => {
            queryClient.invalidateQueries(AccountsRQKey);
            setStatus(Status.Updated);
        },
    });

    if (operation === Operation.Update && accountUpdateMutation.isSuccess) {
        setOperation(Operation.Empty);
        AddNotification('success', i18next.t('user.user_data_updated'));
    } else if (operation === Operation.Update && accountUpdateMutation.isError) {
        setOperation(Operation.Empty);
        AddNotification('danger', i18next.t('user.update_failed'));
    }

    /**
     * Handler for user information submit button.
     */
    const handleSubmitUserInfo = () => {
        const validated =
            // FCFirstNameValidate(userState.profile.firstname) &&
            // FCLastNameValidate(userState.profile.lastname) &&
            // FCEmailValidate(userState.profile.email) &&
            FCDepartmentValidate(userState.profile.department) && FCPhoneNumberValidate(userState.profile.phoneNumber);
        if (!validated) {
            AddNotification('danger', i18next.t('user.validation_failed'));
            return;
        }

        const accountUpdate = userProfile;
        // accountUpdate.email = userState.profile.email;
        // accountUpdate.firstName = userState.profile.firstname;
        // accountUpdate.lastName = userState.profile.lastname;
        accountUpdate.attributes.phoneNumber = [userState.profile.phoneNumber];
        accountUpdate.attributes.department = [userState.profile.department];
        accountUpdate.attributes.locale = [userState.profile.locale];
        accountUpdate.attributes.nickname = [userState.profile.nickname];

        accountUpdateMutation.mutate({
            csrfToken: accountCSRF,
            account: accountUpdate,
        } as IAccountQMRequest);
        setOperation(Operation.Update);
    };

    if (Object.keys(userState.profile).length === 0) {
        return (
            <EmptyState>
                <EmptyStateIcon variant="container" component={Spinner} />
            </EmptyState>
        );
    }

    return (
        <>
            <FCNotifications notifications={notifications} setNotificationsCallback={setNotifications} />
            <PageSection variant={PageSectionVariants.darker}>
                <Title headingLevel="h1">{PageTitle()}</Title>
            </PageSection>
            <PageSection variant={PageSectionVariants.light}>
                <TextContent>
                    <Text component={TextVariants.h2}>{t('personal_info.info')}</Text>
                    <Text component={TextVariants.small} />
                    <Text component={TextVariants.p}>{t('personal_info.all_fields_required')}</Text>
                </TextContent>
            </PageSection>

            <FCStateLoading loading={userProfileResponse.isLoading} />
            <PageSection variant={PageSectionVariants.light}>
                <Form>
                    {/* <FCUsername userState={userState} isDisabled isRequired={false} /> */}
                    <FCFirstName userState={userState} isDisabled isRequired={false} />
                    <FCLastName userState={userState} isDisabled isRequired={false} />
                    <FCEmail userState={userState} isDisabled isRequired />
                    <FCNickname userState={userState} isRequired={false} />
                    <FCPhoneNumber userState={userState} isRequired={false} />
                    <FCDepartment userState={userState} isRequired />
                    <FCLocale userState={userState} isRequired={false} />
                    <ActionGroup>
                        <Button variant="primary" onClick={handleSubmitUserInfo}>
                            {i18next.t('personal_info.update')}
                        </Button>
                    </ActionGroup>
                </Form>
            </PageSection>
        </>
    );
};
