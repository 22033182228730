/* eslint-disable @typescript-eslint/no-explicit-any */

import { UseQueryResult } from 'react-query';
import { GetResponse } from '@app/Handlers/GenericHandler';

export interface IApplication {
    name: string;
    url: string;
    description: string;
}

/**
 * API endpoint URL constant.
 */
const URL = '/api/v1/applications';

/**
 * React Query storage key.
 */
export const ApplicationsRQKey = 'applications';

/**
 * GetAccountDataResponse gets user data from endpoint /account.
 * @returns parsed JSON data if the response is successful
 */
export const GetApplicationsDataResponse = async () => {
    const response = await fetch(`${URL}`);
    return GetResponse(response);
};

// TODO update docs
/**
 * GetAccount parses response from GetAccountDataResponse and returns them as UserData structure.
 * @param resp the response from GetAccountDataResponse
 * @returns UserData and CSRF token if the response is loaded.
 */
export const GetApplications = (resp: UseQueryResult<any, unknown>): [Array<IApplication>, string] => [
    (resp.isLoading ? {} : resp.data.applications) as Array<IApplication>,
    resp.isLoading ? '' : resp.data.csrf,
];
