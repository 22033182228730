import * as React from 'react';
import { FormGroup, TextInput } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import i18next from '@app/Translations/i18n';
import { IUserState, SetValueAtProfile } from '../Helpers/UserState';

export const FCDepartmentValidate = (department: string) => {
    if (department !== '') {
        return true;
    }

    return false;
};

export interface FCDepartmentProps {
    /** User state connector */
    userState: IUserState;
    /** Form item disabled indicator */
    isDisabled?: boolean;
    /** Form item requirement indicator */
    isRequired?: boolean;
}

export const FCDepartment: React.FunctionComponent<FCDepartmentProps> = ({
    userState,
    isDisabled = false,
    isRequired = true,
}: FCDepartmentProps) => {
    const handleDepartmentChange = (value) => {
        SetValueAtProfile(userState, 'department', value);
    };

    return (
        <FormGroup
            label={i18next.t('user.department.name')}
            isRequired={isRequired}
            fieldId="form-firstname-title"
            helperTextInvalid={i18next.t('user.department.invalid')}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            hasNoPaddingTop
        >
            <TextInput
                type="text"
                id="form-department"
                name="form-department"
                aria-describedby="form-department-helper"
                value={userState.profile.department}
                onChange={handleDepartmentChange}
                validated={FCDepartmentValidate(userState.profile.department) ? 'default' : 'error'}
                isDisabled={isDisabled}
            />
        </FormGroup>
    );
};
