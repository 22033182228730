import { UseQueryResult } from 'react-query';
import { GetResponse } from '@app/Handlers/GenericHandler';

/**
 * API endpoint URL constant.
 */
const URL = '/api/v1/version';

/**
 * React Query storage key.
 */
export const VersionRQKey = 'version';

/**
 * Response interface for version
 * @file version.go
 */
export interface IVersion {
    version: string;
}

export const GetVersionResponse = async () => {
    const response = await fetch(URL);
    return GetResponse(response);
};

export const GetVersion = (resp: UseQueryResult<any, unknown>): string =>
    (resp.isLoading ? '' : resp.data.version) as string;
