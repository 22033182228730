import { PageSection, PageSectionVariants, TextContent, Text, Title, TextVariants } from '@patternfly/react-core';
import * as React from 'react';
import { useQuery } from 'react-query';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
    SessionsDevicesRQKey,
    GetAccountSessionsDevicesResponse,
    GetSessionsDevicesData,
    SessionsDevice,
} from '@app/Handlers/API/V1/Account/AccountSessionsDevices';
import { TableComposable, Thead, Tr, Tbody, Td } from '@patternfly/react-table';
import { DeviceActivityTable } from './Components';

export const PageTitle = () => i18next.t('device_activity.title');

export const DeviceActivity: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const sessionsDevicesResponse = useQuery(SessionsDevicesRQKey, GetAccountSessionsDevicesResponse);
    const [sessionsDevices] = GetSessionsDevicesData(sessionsDevicesResponse);

    const [devices, setDevices] = React.useState([] as SessionsDevice[]);

    // Re-render for refetch
    React.useEffect(() => {
        if (!sessionsDevicesResponse.isLoading && sessionsDevicesResponse.isSuccess) {
            setDevices(sessionsDevices);
        }
    }, [sessionsDevicesResponse.isLoading]);

    return (
        <>
            <PageSection variant={PageSectionVariants.darker}>
                <Title headingLevel="h1">{PageTitle()}</Title>
            </PageSection>
            <PageSection variant={PageSectionVariants.light}>
                <TextContent>
                    <Text component={TextVariants.p}>{t('device_activity.info')}</Text>
                    <Text component={TextVariants.small} />
                    <Text component={TextVariants.h2}>{t('device_activity.signed_in')}</Text>
                    <Text />
                </TextContent>
                <TextContent>
                    <TableComposable aria-label="Simple table">
                        <Thead>
                            <Tr />
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>
                                    {devices.map((device) => (
                                        <DeviceActivityTable deviceSession={device} />
                                    ))}
                                </Td>
                            </Tr>
                        </Tbody>
                    </TableComposable>
                </TextContent>
            </PageSection>
        </>
    );
};
