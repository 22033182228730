import * as React from 'react';
import { Routes } from 'react-router-dom';

import { GetRoutes } from '@app/Router/Routes';
import { AuthProvider } from '@app/Router/AuthProvider';

const AppRoutes = (): React.ReactElement => (
    <AuthProvider>
        <Routes>{GetRoutes()}</Routes>
    </AuthProvider>
);

export default AppRoutes;
