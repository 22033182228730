import { ClientSessionInfo, SessionsDevice } from '@app/Handlers/API/V1/Account/AccountSessionsDevices';
import { TextContent, Icon, Label } from '@patternfly/react-core';
import { DesktopIcon } from '@patternfly/react-icons';
import { TableComposable, Tbody, Tr, Th, Td } from '@patternfly/react-table';
import React from 'react';
import { GetReadableTimestamp } from '@app/Utils/Helpers';
import { useTranslation } from 'react-i18next';

export interface FCDeviceActivityTableProps {
    /** Session data */
    deviceSession: SessionsDevice;
}

/**
 * GetClientNames formats arrays of structures into string of client names
 * @param clients
 * @returns string containing concatenated relevant client names
 */
export const GetClientNames = (clients: ClientSessionInfo[]) => {
    const clientsArray = clients.map((client) => client.clientId.split('-')[0]);
    return [...new Set(clientsArray)].map((client) => client).join(', ');
};

export const DeviceActivityTable: React.FunctionComponent<FCDeviceActivityTableProps> = ({
    deviceSession,
}: FCDeviceActivityTableProps) => {
    const { t } = useTranslation();

    return (
        <>
            {deviceSession.sessions.map((session) => (
                <>
                    <TextContent>
                        <Icon isInline>
                            <DesktopIcon />
                        </Icon>{' '}
                        {deviceSession.os}
                        {' / '}
                        {session.browser} <Label color="green">{t('device_activity.current_session')}</Label>
                    </TextContent>
                    <TableComposable variant="compact" aria-label="Simple table of clients" borders={false}>
                        <Tbody>
                            <Tr>
                                <Th width={20}>
                                    <b>{t('device_activity.ip_address')}</b>
                                </Th>
                                <Th width={15}>
                                    <b>{t('device_activity.last_accessed')}</b>
                                </Th>
                                <Th width={25}>
                                    <b>{t('device_activity.clients')}</b>
                                </Th>
                                <Th width={20}>
                                    <b>{t('device_activity.started')}</b>
                                </Th>
                                <Th width={20}>
                                    <b>{t('device_activity.expires')}</b>
                                </Th>
                            </Tr>
                            <Tr>
                                <Td>{session.ipAddress}</Td>
                                <Td>{GetReadableTimestamp(new Date(session.lastAccess * 1000))}</Td>
                                <Td>{GetClientNames(session.clients)}</Td>
                                <Td>{GetReadableTimestamp(new Date(session.started * 1000))}</Td>
                                <Td>{GetReadableTimestamp(new Date(session.expires * 1000))}</Td>
                            </Tr>
                        </Tbody>
                    </TableComposable>
                </>
            ))}
        </>
    );
};
