import {
    PageSection,
    PageSectionVariants,
    TextContent,
    Text,
    Title,
    TextVariants,
    Button,
    Modal,
    ModalVariant,
} from '@patternfly/react-core';
import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
    CredentialsRQKey,
    DeleteCredentialsRequest,
    GetAccountCredentialsResponse,
    GetCredentialsData,
    IDeleteCredentialQMRequest,
    URLConfigureSecurityKey,
    URLConfigureTOTP,
    URLUpdatePassword,
} from '@app/Handlers/API/V1/Account/AccountCredentials';
import { FCTwoFactorAuthnetication } from './Components/TwoFactorAuthentication';
import { FCBasicAuthnetication } from './Components/BasicAuthentication';
import { FCSecurityKeyAuthnetication } from './Components/PasswordlessAuthentication';

export const PageTitle = () => i18next.t('signingin.title');

export interface ICredentialsRow {
    id: string;
    userLabel: string;
    cretedDate: number;
}

export const Signingin: React.FunctionComponent = () => {
    let { t } = useTranslation();

    const queryClient = useQueryClient();
    const credentialsResponse = useQuery([CredentialsRQKey], GetAccountCredentialsResponse);
    const [credentialsData, csrfToken] = GetCredentialsData(credentialsResponse);

    const [passwords, setPasswords] = React.useState([] as ICredentialsRow[]);
    const [otp, setOtp] = React.useState([] as ICredentialsRow[]);
    const [securityKey, setSecurityKey] = React.useState([] as ICredentialsRow[]);
    const [otpToDelete, setOtpToDelete] = React.useState({} as ICredentialsRow);
    const [keyToDelete, setKeyToDelete] = React.useState({} as ICredentialsRow);

    const [isDeleteTOTPDeviceModalOpen, setIsDeleteTOTPDeviceModalOpen] = React.useState(false);
    const [isDeleteSecurityKeyModalOpen, setIsDeleteSecurityKeyModalOpen] = React.useState(false);

    const handleIsDeleteTOTPDeviceModalOpen = () => {
        if (isDeleteTOTPDeviceModalOpen) {
            setOtpToDelete({} as ICredentialsRow);
        }
        setIsDeleteTOTPDeviceModalOpen(!isDeleteTOTPDeviceModalOpen);
    };
    const handleIsDeleteSecurityKeyModalOpen = () => {
        if (isDeleteSecurityKeyModalOpen) {
            setKeyToDelete({} as ICredentialsRow);
        }
        setIsDeleteSecurityKeyModalOpen(!isDeleteSecurityKeyModalOpen);
    };

    const getCredentailsFromResponse = (dataType: string, defaultName: string) => {
        const newCredentails = [] as ICredentialsRow[];
        credentialsData
            .filter((data) => data.type === dataType)
            .map((data) =>
                data.userCredentialMetadatas.map((password) =>
                    newCredentails.push({
                        id: password.credential.id,
                        userLabel: password.credential.userLabel === '' ? defaultName : password.credential.userLabel,
                        cretedDate: password.credential.createdDate,
                    } as ICredentialsRow),
                ),
            );
        return newCredentails;
    };

    const realoadData = () => {
        const newPasswords = getCredentailsFromResponse('password', 'Password');
        const newOtps = getCredentailsFromResponse('otp', 'Otp device');
        const newSecurityKeys = getCredentailsFromResponse('webauthn', 'Security key');
        setPasswords(newPasswords);
        setOtp(newOtps);
        setSecurityKey(newSecurityKeys);
    };

    // Re-render for refetch
    React.useEffect(() => {
        if (!credentialsResponse.isLoading && credentialsResponse.isSuccess) {
            realoadData();
        }
    }, [credentialsResponse.isLoading]);

    // Re-render for refetch
    React.useEffect(() => {
        if (credentialsData.length > 0) {
            realoadData();
        }
    }, [credentialsData]);

    /**
     * Handler for user information submit button.
     */
    const handleUpdatePassword = () => {
        window.location.href = URLUpdatePassword;
    };
    /**
     * Handler for user information submit button.
     */
    const handleConfigureTOTP = () => {
        window.location.href = URLConfigureTOTP;
    };
    /**
     * Handler for user information submit button.
     */
    const handleConfigureSecurityKey = () => {
        window.location.href = URLConfigureSecurityKey;
    };

    const credentialsDeleteMutation = useMutation(DeleteCredentialsRequest, {
        onSuccess: () => {
            queryClient.invalidateQueries([CredentialsRQKey]);
        },
    });

    /**
     * Handler for delete TOTP button.
     */
    const handleOTPDeviceRemove = () => {
        const { id } = otpToDelete;
        if (id !== '') {
            credentialsDeleteMutation.mutate({ csrfToken, uid: id } as IDeleteCredentialQMRequest);
        }
        handleIsDeleteTOTPDeviceModalOpen();
    };
    /**
     * Handler for delete security key button.
     */
    const handleSecurityDeviceRemove = () => {
        const { id } = keyToDelete;
        if (id !== '') {
            credentialsDeleteMutation.mutate({ csrfToken, uid: id } as IDeleteCredentialQMRequest);
        }
        handleIsDeleteSecurityKeyModalOpen();
    };

    const DeleteTOTPDeviceModal = (
        <Modal
            aria-label="delete-otp-device"
            variant={ModalVariant.small}
            title={''.concat(i18next.t('signingin.second_factor.remove_title'))}
            isOpen={isDeleteTOTPDeviceModalOpen}
            onClose={handleIsDeleteTOTPDeviceModalOpen}
            actions={[
                <Button key="confirm" variant="danger" onClick={handleOTPDeviceRemove}>
                    {i18next.t('common.Delete')}
                </Button>,
                <Button key="cancel" variant="link" onClick={handleIsDeleteTOTPDeviceModalOpen}>
                    {i18next.t('common.Cancel')}
                </Button>,
            ]}
        >
            {i18next.t('signingin.second_factor.remove_description')} {otpToDelete.userLabel} ?
        </Modal>
    );

    const DeleteSecurityKeyModal = (
        <Modal
            aria-label="delete-security-key"
            variant={ModalVariant.small}
            title={''.concat(i18next.t('signingin.webauthn.remove_title'))}
            isOpen={isDeleteSecurityKeyModalOpen}
            onClose={handleIsDeleteSecurityKeyModalOpen}
            actions={[
                <Button key="confirm" variant="danger" onClick={handleSecurityDeviceRemove}>
                    {i18next.t('common.Delete')}
                </Button>,
                <Button key="cancel" variant="link" onClick={handleIsDeleteSecurityKeyModalOpen}>
                    {i18next.t('common.Cancel')}
                </Button>,
            ]}
        >
            {i18next.t('signingin.webauthn.remove_description')} {keyToDelete.userLabel} ?
        </Modal>
    );

    return (
        <>
            {DeleteTOTPDeviceModal}
            {DeleteSecurityKeyModal}
            <PageSection variant={PageSectionVariants.darker}>
                <Title headingLevel="h1">{PageTitle()}</Title>
            </PageSection>
            <PageSection variant={PageSectionVariants.light}>
                <TextContent>
                    <Text component={TextVariants.p}>{t('signingin.info')}</Text>
                </TextContent>
            </PageSection>
            <PageSection variant={PageSectionVariants.light}>
                <FCBasicAuthnetication passwords={passwords} handleUpdatePassword={handleUpdatePassword} />
            </PageSection>
            <PageSection variant={PageSectionVariants.light}>
                <FCTwoFactorAuthnetication
                    credentialsRows={otp}
                    setOtpToDelete={setOtpToDelete}
                    handleIsDeleteTOTPDeviceModalOpen={handleIsDeleteTOTPDeviceModalOpen}
                    handleConfigureTOTP={handleConfigureTOTP}
                />
                <FCSecurityKeyAuthnetication
                    credentialsRows={securityKey}
                    setKeyToDelete={setKeyToDelete}
                    handleIsDeleteSecurityKeyModalOpen={handleIsDeleteSecurityKeyModalOpen}
                    handleConfigureSecurityKey={handleConfigureSecurityKey}
                />
            </PageSection>
        </>
    );
};
