import { GetReadableTimestamp } from '@app/Utils/Helpers';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    CardHeaderMain,
    CardActions,
    DataList,
    DataListItemRow,
    DataListItemCells,
    DataListCell,
    DataListAction,
    DataListItem,
    TextContent,
} from '@patternfly/react-core';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICredentialsRow } from '../Signingin';

export interface FCSecurityKeyAuthneticationProps {
    credentialsRows: ICredentialsRow[];
    setKeyToDelete: React.Dispatch<React.SetStateAction<ICredentialsRow>>;
    handleIsDeleteSecurityKeyModalOpen: () => void;
    handleConfigureSecurityKey: () => void;
}

export const FCSecurityKeyAuthnetication: React.FunctionComponent<FCSecurityKeyAuthneticationProps> = ({
    credentialsRows,
    setKeyToDelete,
    handleIsDeleteSecurityKeyModalOpen,
    handleConfigureSecurityKey,
}: FCSecurityKeyAuthneticationProps) => {
    let { t } = useTranslation();

    return (
        <Card isPlain>
            <TextContent>
                <CardTitle component="h2">{t('signingin.webauthn.title')}</CardTitle>
            </TextContent>
            <CardHeader>
                <CardHeaderMain>
                    <b>{t('signingin.webauthn.auth_app')}</b>
                </CardHeaderMain>
                <CardActions>
                    <Button variant="link" onClick={handleConfigureSecurityKey}>
                        {i18next.t('signingin.webauthn.add_otp_device')}
                    </Button>
                </CardActions>
            </CardHeader>
            <CardBody>{t('signingin.webauthn.info')}</CardBody>
            <CardBody>
                <DataList aria-label="">
                    {credentialsRows.map((row) => (
                        <DataListItem>
                            <DataListItemRow>
                                <DataListItemCells
                                    dataListCells={[
                                        <DataListCell key="user label">{row.userLabel}</DataListCell>,

                                        <DataListCell key="time created">
                                            <b>{i18next.t('signingin.webauthn.created')} </b>
                                            {GetReadableTimestamp(new Date(row.cretedDate))}
                                        </DataListCell>,
                                    ]}
                                />
                                <DataListAction id="" aria-labelledby="" aria-label="">
                                    <Button
                                        variant="danger"
                                        // isDisabled={credentialsRows.length < 2}
                                        onClick={() => {
                                            handleIsDeleteSecurityKeyModalOpen();
                                            setKeyToDelete(row);
                                        }}
                                    >
                                        {i18next.t('signingin.webauthn.remove')}
                                    </Button>
                                </DataListAction>
                            </DataListItemRow>
                        </DataListItem>
                    ))}
                </DataList>
            </CardBody>
        </Card>
    );
};
