import * as React from 'react';
import { FormGroup, TextInput } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';

import { IUserProfile, IUserState, SetValueAtProfile } from '../Helpers/UserState';
import i18next from '@app/Translations/i18n';

export const FCPhoneNumberValidate = (phoneNumber: string): boolean => {
    const phoneNumberRegex = /^(\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/;

    if (phoneNumber === '' && phoneNumber !== undefined) {
        return true;
    }
    if (phoneNumber.search(phoneNumberRegex) !== -1) {
        return true;
    }

    return false;
};

export const FCPhoneNumberValidateAll = (user: IUserProfile): boolean => {
    if (!user?.phoneNumber) {
        return true;
    }

    return FCPhoneNumberValidate(user.phoneNumber);
};

export interface FCPhoneNumberProps {
    /** User state connector */
    userState: IUserState;
    /** Form item disabled indicator */
    isDisabled?: boolean;
    /** Form item requirement indicator */
    isRequired?: boolean;
}

export const FCPhoneNumber: React.FunctionComponent<FCPhoneNumberProps> = ({
    userState,
    isDisabled = false,
    isRequired = true,
}: FCPhoneNumberProps) => {
    const helperInvalidDefault = i18next.t('user.phone_number.invalid');

    const helperTextInvalid = !FCPhoneNumberValidateAll(userState.profile) ? helperInvalidDefault : '';

    const handlePhoneNumberChange = (value) => {
        SetValueAtProfile(userState, 'phoneNumber', value);
    };

    return (
        <FormGroup
            label={i18next.t('user.phone_number.name')}
            isRequired={isRequired}
            fieldId="form-phoneNumber-title"
            helperTextInvalid={helperTextInvalid}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            hasNoPaddingTop
        >
            <TextInput
                type="text"
                id="form-phoneNumber"
                name="form-phoneNumber"
                aria-describedby="form-phoneNumber-helper"
                value={userState.profile.phoneNumber}
                onChange={handlePhoneNumberChange}
                validated={FCPhoneNumberValidateAll(userState.profile) ? 'default' : 'error'}
                isDisabled={isDisabled}
            />
        </FormGroup>
    );
};
