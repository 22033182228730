import { Card, CardTitle, CardBody, SplitItem, Icon, Split } from '@patternfly/react-core';
import React from 'react';
import { UserData } from '@app/Handlers/API/V1/Account/Account';
import { UserIcon } from '@patternfly/react-icons';

export interface FCAccountInformationProps {
    /** Session data */
    userProfile: UserData;
}

export const AccountInformation: React.FunctionComponent<FCAccountInformationProps> = ({
    userProfile,
}: FCAccountInformationProps) => (
    <Card component="div">
        <CardTitle>Základní informace</CardTitle>
        <CardBody>
            <Split hasGutter>
                <SplitItem>
                    <Icon size="xl">
                        <UserIcon />
                    </Icon>
                </SplitItem>
                <SplitItem isFilled>
                    {`${userProfile.firstName} ${userProfile.lastName}`}
                    <br />
                    {userProfile.email}
                </SplitItem>
            </Split>
        </CardBody>
    </Card>
);
