import * as React from 'react';
import { FormGroup, TextInput } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';

import i18next from '@app/Translations/i18n';
import { IUserProfile, IUserState, SetValueAtProfile } from '../Helpers/UserState';

export const FCEmailValidate = (email: string): boolean => {
    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email !== '' && email !== undefined) {
        if (email.search(emailRegex) !== -1) {
            return true;
        }
    }

    return false;
};

export const FCEmailValidateAll = (user: IUserProfile): boolean => FCEmailValidate(user.email);

export interface FCEmailProps {
    /** User state connector */
    userState: IUserState;
    /** Form item disabled indicator */
    isDisabled?: boolean;
    /** Form item requirement indicator */
    isRequired?: boolean;
}

export const FCEmail: React.FunctionComponent<FCEmailProps> = ({
    userState,
    isDisabled = false,
    isRequired = true,
}: FCEmailProps) => {
    const helperInvalidDefault = i18next.t('user.email.invalid_default');

    const helperTextInvalid = !FCEmailValidateAll(userState.profile) ? helperInvalidDefault : '';

    const handleEmailChange = (value) => {
        SetValueAtProfile(userState, 'email', value);
    };

    return (
        <FormGroup
            label="E-mail"
            isRequired={isRequired}
            fieldId="form-email-title"
            helperTextInvalid={helperTextInvalid}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            hasNoPaddingTop
        >
            <TextInput
                type="text"
                id="form-email"
                name="form-email"
                aria-describedby="form-email-helper"
                value={userState.profile.email}
                onChange={handleEmailChange}
                validated={FCEmailValidateAll(userState.profile) ? 'default' : 'error'}
                isDisabled={isDisabled}
            />
        </FormGroup>
    );
};
