import { GetResponse } from '@app/Handlers/GenericHandler';

/**
 * API endpoint URL constant.
 */
const URL = '/auth/status';

/**
 * React Query storage key.
 */
export const AuthStatusRQKey = 'auth-status';

/**
 * Response interface for authentication status.
 * @file auth_status.go
 */
export interface IAuthStatus {}

export const GetAuthStatusResponse = async () => {
    const response = await fetch(URL);
    return GetResponse(response);
};
