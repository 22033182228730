/* eslint-disable @typescript-eslint/no-explicit-any */

import { UseQueryResult } from 'react-query';
import { GetResponse } from '@app/Handlers/GenericHandler';
import { DeleteParams } from '@app/Handlers/Helpers';

/**
 * API endpoint URL constant.
 */
const URLSessionsDevices = '/api/v1/account/sessions/devices';

/**
 * React Query storage key.
 */
export const SessionsDevicesRQKey = 'devices';

export interface ClientSessionInfo {
    clientId: string;
    clientName: string;
    userConsentRequired: boolean;
    inUse: boolean;
    offlineAccess: boolean;
}

export interface Session {
    id: string;
    ipAddress: string;
    started: number;
    lastAccess: number;
    expires: number;
    clients: ClientSessionInfo[];
    browser: string;
    current: boolean;
}

export interface SessionsDevice {
    os: string;
    osVersion: string;
    device: string;
    lastAccess: number;
    current: boolean;
    sessions: Session[];
    mobile: boolean;
}

export const GetAccountSessionsDevicesResponse = async () => {
    const response = await fetch(`${URLSessionsDevices}`);
    return GetResponse(response);
};

export const GetSessionsDevicesData = (resp: UseQueryResult<any, unknown>): [SessionsDevice[], string] => [
    (resp.isLoading ? {} : resp.data.devices) as SessionsDevice[],
    resp.isLoading ? '' : resp.data.csrf,
];
