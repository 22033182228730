import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { EmptyState, EmptyStateBody, EmptyStateIcon, Title, PageSection } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';

import i18next from '@app/Translations/i18n';

const ErrorInternal: React.FunctionComponent = () => (
    <PageSection>
        <EmptyState>
            <EmptyStateIcon icon={ExclamationCircleIcon} />
            <Title headingLevel="h4" size="lg">
                {i18next.t('error.internal')}
            </Title>
            <EmptyStateBody>{i18next.t('error.internal_description')}</EmptyStateBody>
            <br />
            <br />
            <NavLink to="/" className="pf-c-button">
                {i18next.t('error.return')}
            </NavLink>
        </EmptyState>
    </PageSection>
);

export default ErrorInternal;
