import * as React from 'react';
import { Alert, AlertGroup, AlertActionCloseButton } from '@patternfly/react-core';

export type NotificationVariant = 'danger' | 'default' | 'success' | 'warning' | 'info' | undefined;

export interface INotificationContent {
    variant: NotificationVariant;
    title: string;
}

export interface FCNotificationsProps {
    notifications: Array<INotificationContent>;
    setNotificationsCallback: any;
}

export const FCNotifications: React.FunctionComponent<FCNotificationsProps> = ({
    notifications = [] as Array<INotificationContent>,
    setNotificationsCallback,
}: FCNotificationsProps) => (
    <AlertGroup isToast>
        {notifications.map(({ variant, title }) => (
            <Alert
                isLiveRegion
                variant={variant}
                title={title}
                timeout={5000}
                actionClose={
                    <AlertActionCloseButton onClose={() => setNotificationsCallback(notifications.splice(1))} />
                }
            />
        ))}
    </AlertGroup>
);
