import * as React from 'react';
import { FormGroup, FormSelect, FormSelectOption, TextInput } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import i18next from '@app/Translations/i18n';
import { IUserState, SetValueAtProfile } from '../Helpers/UserState';

export interface FCLocaleProps {
    /** User state connector */
    userState: IUserState;
    /** Form item disabled indicator */
    isDisabled?: boolean;
    /** Form item requirement indicator */
    isRequired?: boolean;
}

export const FCLocale: React.FunctionComponent<FCLocaleProps> = ({
    userState,
    isDisabled = false,
    isRequired = false,
}: FCLocaleProps) => {
    const handleLanguageChange = (value) => {
        SetValueAtProfile(userState, 'locale', value);
    };

    const options = [
        { value: 'cs', label: 'cs', disabled: false },
        { value: 'en', label: 'en', disabled: false },
    ];

    return (
        <FormGroup
            label={i18next.t('user.language.name')}
            isRequired={isRequired}
            fieldId="form-department-title"
            helperText={i18next.t('user.language.description')}
            helperTextInvalid={i18next.t('user.language.invalid')}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            hasNoPaddingTop
        >
            <FormSelect
                value={userState.profile.locale}
                onChange={handleLanguageChange}
                aria-label="FormSelect Language"
                isDisabled={isDisabled}
            >
                {options.map((option) => (
                    <FormSelectOption
                        isDisabled={option.disabled}
                        key={option.value}
                        value={option.value}
                        label={option.label}
                    />
                ))}
            </FormSelect>
        </FormGroup>
    );
};
