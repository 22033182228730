/**
 * Function provides predefined settings for POST request.
 *
 * @param csrfToken
 * @param requestBody
 * @returns
 */
export const PostParams = (csrfToken: string, requestBody: string): RequestInit => ({
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: requestBody, // body data type must match "Content-Type" header
});

/**
 * Function provides predefined settings for PUT request.
 *
 * @param csrfToken
 * @param requestBody
 * @returns
 */
export const PutParams = (csrfToken: string, requestBody: string): RequestInit => ({
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: requestBody, // body data type must match "Content-Type" header
});

/**
 * Function provides predefined settings for DELETE request.
 *
 * @param csrfToken
 * @param requestBody
 * @returns
 */
export const DeleteParams = (csrfToken: string, requestBody: string): RequestInit => ({
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: requestBody, // body data type must match "Content-Type" header
});
