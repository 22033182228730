import * as React from 'react';
import { FormGroup, TextInput } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import i18next from '@app/Translations/i18n';
import { IUserState, SetValueAtProfile } from '../Helpers/UserState';

export const FCFirstNameValidate = (firstName: string) => {
    if (firstName !== '') {
        return true;
    }

    return false;
};

export interface FCFirstNameProps {
    /** User state connector */
    userState: IUserState;
    /** Form item disabled indicator */
    isDisabled?: boolean;
    /** Form item requirement indicator */
    isRequired?: boolean;
}

export const FCFirstName: React.FunctionComponent<FCFirstNameProps> = ({
    userState,
    isDisabled = false,
    isRequired = true,
}: FCFirstNameProps) => {
    const handleFirstNameChange = (value) => {
        SetValueAtProfile(userState, 'firstname', value);
    };

    return (
        <FormGroup
            label={i18next.t('user.firstname.name')}
            isRequired={isRequired}
            fieldId="form-firstname-title"
            helperTextInvalid={i18next.t('user.firstname.invalid')}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            hasNoPaddingTop
        >
            <TextInput
                type="text"
                id="form-firstname"
                name="form-firstname"
                aria-describedby="form-firstname-helper"
                value={userState.profile.firstname}
                onChange={handleFirstNameChange}
                validated={FCFirstNameValidate(userState.profile.firstname) ? 'default' : 'error'}
                isDisabled={isDisabled}
            />
        </FormGroup>
    );
};
