import * as React from 'react';
import { useQuery } from 'react-query';
import { Navigate, useLocation } from 'react-router-dom';
import { Spinner, EmptyState, EmptyStateIcon, Title, EmptyStateVariant } from '@patternfly/react-core';
import { AuthUserRQKey, GetAuthUser, GetAuthUserResponse } from '@app/Handlers/Auth/User';
import { GetVersion, GetVersionResponse, VersionRQKey } from '@app/Handlers/API/V1/Version/Version';
import { AuthStatusRQKey, GetAuthStatusResponse } from '@app/Handlers/Auth/Status';
import { useAuth } from '@app/Router/AuthProvider';
import { GenericHandlerError } from '@app/Handlers/GenericHandler';

import i18next from '@app/Translations/i18n';

const isLoggedIn = (authStatus, authUser) => authStatus === 200 && authUser !== undefined;

const isAuthorized = (authStatus, authResponse, versionResponse) =>
    authStatus === 200 && authResponse.isSuccess && versionResponse.isSuccess;

const isUnauthorized = (authStatus, authStatusResponse) =>
    (authStatus === 401 || authStatus === 403) && !authStatusResponse.isLoading;

const Login: React.FunctionComponent = () => {
    const authUrl = '/auth';
    const auth = useAuth();
    const location = useLocation();

    const [authStatus, setAuthStatus] = React.useState(0);

    const authStatusResponse = useQuery([AuthStatusRQKey, false], GetAuthStatusResponse, {
        retry: false,
        onError(err: GenericHandlerError) {
            if (err.status === 401) {
                setAuthStatus(401);
            } else if (err.status === 403) {
                setAuthStatus(403);
            }
        },
        onSuccess() {
            setAuthStatus(200);
        },
    });

    const authUserResponse = useQuery(AuthUserRQKey, GetAuthUserResponse, { enabled: authStatus === 200 });
    const versionResponse = useQuery(VersionRQKey, GetVersionResponse, { enabled: authStatus === 200 });

    const locationState =
        location.state === undefined || location.state === null ? undefined : (location.state as { from: Location });
    const locationFrom = locationState === undefined || location.state === null ? '/' : locationState.from.pathname;

    if (isLoggedIn(authStatus, auth.user)) {
        return <Navigate to={locationFrom} />;
    }
    if (isUnauthorized(authStatus, authStatusResponse)) {
        window.location.href = authUrl;
    }
    if (isAuthorized(authStatus, authUserResponse, versionResponse)) {
        auth.login(GetAuthUser(authUserResponse), GetVersion(versionResponse));
        return <Navigate to={locationFrom} />;
    }

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#151515' }}>
            <EmptyState variant={EmptyStateVariant.xl}>
                <EmptyStateIcon variant="container" component={Spinner} />
                <Title id="redirect-title" size="lg" headingLevel="h4" style={{ color: '#CDCDCD' }}>
                    {i18next.t('common.redirect')}
                </Title>
            </EmptyState>
        </div>
    );
};

export default Login;
