import * as React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '@app/Router/AuthProvider';

import ErrorInternal from '@app/Pages/Error/ErrorInternal';
import ErrorConnection from '@app/Pages/Error/ErrorConnection';
import ErrorNotFound from '@app/Pages/Error/ErrorNotFound';
import ErrorForbidden from '@app/Pages/Error/ErrorForbidden';
import Login from '@app/Pages/Login/Login';
import DefaultAppLayout from '@app/Layouts/DefaultAppLayout';
import PublicAppLayout from '@app/Layouts/PublicAppLayout';

import i18next from '@app/Translations/i18n';
import { AccountInformation } from '@app/Pages/AccountInformation/AccountInformation';
import { DeviceActivity } from '@app/Pages/DeviceActivity/DeviceActivity';
import { Applications } from '@app/Pages/Applications/Applications';
import { Signingin } from '@app/Pages/Signingin/Signingin';
import { Dashboard } from '@app/Pages/Dashboard/Dashboard';

export interface IAppRoute {
    component: React.FunctionComponent;
    title: string;
    label: string;
    path: string;
    displayNav: boolean;
    privateRoute: boolean;
    subRoutes: IAppRoute[];
}

export const routes = () => [
    {
        component: Dashboard,
        title: i18next.t('dashboard.title') + i18next.t('routes.account'),
        label: i18next.t('dashboard.title'),
        path: '/',
        displayNav: true,
        privateRoute: true,
        subRoutes: [] as IAppRoute[],
    },
    {
        component: AccountInformation,
        title: i18next.t('personal_info.title') + i18next.t('routes.account'),
        label: i18next.t('personal_info.title'),
        path: '/account',
        displayNav: true,
        privateRoute: true,
        subRoutes: [] as IAppRoute[],
    },
    {
        component: Signingin,
        title: i18next.t('account_security') + i18next.t('routes.account'),
        label: i18next.t('account_security'),
        path: '/signingin',
        displayNav: true,
        privateRoute: true,
        subRoutes: [
            {
                component: Signingin,
                title: i18next.t('signingin.title') + i18next.t('routes.account'),
                label: i18next.t('signingin.title'),
                path: '/signingin',
                displayNav: true,
                privateRoute: true,
                subRoutes: [] as IAppRoute[],
            },
            {
                component: DeviceActivity,
                title: i18next.t('device_activity.title') + i18next.t('routes.account'),
                label: i18next.t('device_activity.title'),
                path: '/device-activity',
                displayNav: true,
                privateRoute: true,
                subRoutes: [] as IAppRoute[],
            },
        ] as IAppRoute[],
    },
    // {
    //     component: Applications,
    //     title: i18next.t('applications.title') + i18next.t('routes.account'),
    //     label: i18next.t('applications.title'),
    //     path: '/applications',
    //     displayNav: true,
    //     privateRoute: true,
    //     subRoutes: [] as IAppRoute[],
    // },
    {
        component: Login,
        title: i18next.t('routes.login') + i18next.t('routes.account'),
        label: i18next.t('routes.login'),
        path: '/login',
        displayNav: false,
        privateRoute: false,
        subRoutes: [] as IAppRoute[],
    },
    {
        component: ErrorInternal,
        title: i18next.t('routes.error') + i18next.t('routes.account'),
        label: i18next.t('routes.error'),
        path: '/error',
        displayNav: false,
        privateRoute: true,
        subRoutes: [] as IAppRoute[],
    },
    {
        component: ErrorForbidden,
        title: i18next.t('routes.error_forbidden') + i18next.t('routes.account'),
        label: i18next.t('routes.error_forbidden'),
        path: '/error/forbidden',
        displayNav: false,
        privateRoute: true,
        subRoutes: [] as IAppRoute[],
    },
    {
        component: ErrorNotFound,
        title: i18next.t('routes.error_not_found') + i18next.t('routes.account'),
        label: i18next.t('routes.error_not_found'),
        path: '/error/not-found',
        displayNav: false,
        privateRoute: true,
        subRoutes: [] as IAppRoute[],
    },
    {
        component: ErrorConnection,
        title: i18next.t('routes.error_connection_lost') + i18next.t('routes.account'),
        label: i18next.t('routes.error_connection_lost'),
        path: '/error/connection-lost',
        displayNav: false,
        privateRoute: true,
        subRoutes: [] as IAppRoute[],
    },
];

/**
 * Flatten nested routes into simple array
 * @param rts Array of nested routes
 * @returns Simple flatten routes array
 */
const FlattenRoutes = (rts: IAppRoute[]) => {
    let flattened: Array<IAppRoute> = [] as Array<IAppRoute>;
    for (const r of rts) {
        flattened.push(r);
        const tmp = FlattenRoutes(r.subRoutes);
        flattened = flattened.concat(tmp);
    }
    return flattened;
};

export const GetRoutes = (): React.ReactElement[] => {
    const generatedRoutes: React.ReactElement[] = [];
    const routesList = routes();
    const flatRoutes = FlattenRoutes(routesList);

    for (const r of flatRoutes) {
        let component: React.ReactElement;

        if (r.privateRoute) {
            component = (
                <RequireAuth>
                    <DefaultAppLayout title={r.title}>
                        <r.component />
                    </DefaultAppLayout>
                </RequireAuth>
            );
        } else {
            component = (
                <PublicAppLayout title={r.title}>
                    <r.component />
                </PublicAppLayout>
            );
        }

        generatedRoutes.push(<Route path={r.path} element={component} />);
    }

    generatedRoutes.push(
        <Route
            path="*"
            element={
                <PublicAppLayout title={i18next.t('routes.login')}>
                    <ErrorNotFound />
                </PublicAppLayout>
            }
        />,
    );

    return generatedRoutes;
};
