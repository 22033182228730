import * as React from 'react';
import { EmptyState, EmptyStateBody, EmptyStateIcon, Title, PageSection } from '@patternfly/react-core';
import DisconnectedIcon from '@patternfly/react-icons/dist/js/icons/disconnected-icon';

import i18next from '@app/Translations/i18n';

const ErrorConnection: React.FunctionComponent = () => (
    <PageSection>
        <EmptyState>
            <EmptyStateIcon icon={DisconnectedIcon} />
            <Title headingLevel="h4" size="lg">
                {i18next.t('error.connection_lost')}
            </Title>
            <EmptyStateBody>{i18next.t('error.connection_lost_description')}</EmptyStateBody>
        </EmptyState>
    </PageSection>
);

export default ErrorConnection;
