import {
    PageSection,
    PageSectionVariants,
    TextContent,
    Title,
    Flex,
    FlexItem,
    Divider,
    CardTitle,
    CardBody,
    Card,
} from '@patternfly/react-core';
import * as React from 'react';
import { useQuery } from 'react-query';

import { FCStateLoading } from '@app/Components/States/StateLoading';
import { AccountsRQKey, GetAccount, GetAccountDataResponse } from '@app/Handlers/API/V1/Account/Account';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@app/Router/AuthProvider';
import {
    ApplicationsRQKey,
    GetApplications,
    GetApplicationsDataResponse,
    IApplication,
} from '@app/Handlers/API/V1/Applications/Applications';
import { Applications } from './Applications';
import { Contacts } from './Contacts';
import { AccountInformation } from './AccountInformation';

export const PageTitle = () => i18next.t('dashboard.title');

export const Dashboard: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const auth = useAuth();
    const authUser = auth.user;

    const userProfileResponse = useQuery(AccountsRQKey, GetAccountDataResponse);
    const [userProfile] = GetAccount(userProfileResponse);

    const applicationsResponse = useQuery(ApplicationsRQKey, GetApplicationsDataResponse);
    const [applications] = GetApplications(applicationsResponse);

    const [accessibleApps, setAccessibleApps] = React.useState([] as Array<IApplication>);

    // Re-render for refetch
    React.useEffect(() => {
        if (authUser && applications.length > 0 && authUser.roles) {
            const userApps = authUser.roles.map((role) => role.split('-')[0]);
            const filteredApps = applications.filter((app) => userApps.some((userApp) => app.description === userApp));
            setAccessibleApps(filteredApps);
        }
    }, [authUser, applications]);

    return (
        <>
            <PageSection variant={PageSectionVariants.darker}>
                <Title headingLevel="h1">{PageTitle()}</Title>
            </PageSection>
            <FCStateLoading loading={userProfileResponse.isLoading} />
            <PageSection variant={PageSectionVariants.light}>
                <Flex fullWidth={{ default: 'fullWidth' }}>
                    <Flex flex={{ default: 'flex_1' }}>
                        <FlexItem fullWidth={{ default: 'fullWidth' }}>
                            <AccountInformation userProfile={userProfile} />
                            <br />
                            {authUser && authUser.contacts && <Contacts contacts={authUser.contacts} />}
                            <br />
                            <Card component="div">
                                <CardTitle>{i18next.t(`dashboard.technical-support.title`)}</CardTitle>
                                <CardBody>
                                    <TextContent>{i18next.t(`dashboard.technical-support.description`)}</TextContent>
                                </CardBody>
                            </Card>
                        </FlexItem>
                    </Flex>
                    <Divider orientation={{ default: 'vertical' }} />
                    <Flex flex={{ default: 'flex_1' }}>
                        <FlexItem fullWidth={{ default: 'fullWidth' }}>
                            <Applications applications={accessibleApps} />
                        </FlexItem>
                    </Flex>
                </Flex>
            </PageSection>
        </>
    );
};
