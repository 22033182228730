import {
    Card,
    CardTitle,
    CardBody,
    DataList,
    DataListCell,
    DataListItemCells,
    DataListItemRow,
} from '@patternfly/react-core';
import i18next from 'i18next';
import React from 'react';

export interface FCContactsProps {
    /** Contacts to be showned */
    contacts: string[];
}

export const Contacts: React.FunctionComponent<FCContactsProps> = ({ contacts }: FCContactsProps) => (
    <Card component="div">
        <CardTitle>{i18next.t('dashboard.contacts')}</CardTitle>
        <CardBody>
            {/* <TextContent>
                                        <TextList>
                                            {authUser &&
                                                authUser.contacts.map((contact) => {
                                                    const name = contact.split(":")[0]
                                                    const email = contact.split(":")[1]
                                                    return(
                                                    <TextListItem>{`${name} <${email}`}</TextListItem>
                                                )})}
                                        </TextList>
                                    </TextContent> */}
            <DataList aria-label="organizations contacts" isCompact>
                {contacts.map((contact) => {
                    const nameContact = contact.split(':')[0];
                    const emailContact = contact.split(':')[1];
                    return (
                        <DataListItemRow>
                            <DataListItemCells
                                dataListCells={[
                                    <DataListCell key={`name-${nameContact}`}>{nameContact}</DataListCell>,
                                    <DataListCell key={`email-${nameContact}`}>{emailContact}</DataListCell>,
                                ]}
                            />
                        </DataListItemRow>
                    );
                })}
            </DataList>
        </CardBody>
    </Card>
);
