import React from 'react';

export interface IUserProfile {
    id: string;
    username: string;
    firstname: string;
    lastname: string;
    nickname: string;
    email: string;
    phoneNumber: string;
    locale: string;
    department: string;
}

export interface IUserState {
    profile: IUserProfile;
    setProfile: React.Dispatch<React.SetStateAction<IUserProfile>>;
}

export const GetUserProfile = (userState: IUserState): IUserProfile => userState.profile;

export const SetValueAtProfile = (userState: IUserState, prop: string, value: string | number) => {
    const account = Object.assign({} as IUserProfile, userState.profile);

    if (prop in account) {
        account[prop] = value;
    }

    userState.setProfile(account);
};

export const GetProfileSetter = (userState: IUserState): React.Dispatch<React.SetStateAction<IUserProfile>> =>
    userState.setProfile;
