import * as React from 'react';
import { FormGroup, TextInput } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import i18next from '@app/Translations/i18n';
import { IUserState, SetValueAtProfile } from '../Helpers/UserState';

export const FCLastNameValidate = (lastName: string) => {
    if (lastName !== '') {
        return true;
    }

    return false;
};

export interface FCLastNameProps {
    /** User state connector */
    userState: IUserState;
    /** Select box disabled indicator */
    isDisabled?: boolean;
    /** Form item requirement indicator */
    isRequired?: boolean;
}

export const FCLastName: React.FunctionComponent<FCLastNameProps> = ({
    userState,
    isDisabled = false,
    isRequired = true,
}: FCLastNameProps) => {
    const handleLastNameChange = (value) => {
        SetValueAtProfile(userState, 'lastname', value);
    };

    return (
        <FormGroup
            label={i18next.t('user.lastname.name')}
            isRequired={isRequired}
            fieldId="form-lastname-title"
            helperTextInvalid={i18next.t('user.lastname.invalid')}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            hasNoPaddingTop
        >
            <TextInput
                type="text"
                id="form-lastname"
                name="form-lastname"
                aria-describedby="form-lastname-helper"
                value={userState.profile.lastname}
                onChange={handleLastNameChange}
                validated={FCLastNameValidate(userState.profile.lastname) ? 'default' : 'error'}
                isDisabled={isDisabled}
            />
        </FormGroup>
    );
};
